/**
 * Utils Functions.
 */

import DOMPurify from 'dompurify';
import validUrl from 'valid-url';
import { siteURL } from '../../client-config';
import { isEmpty } from 'lodash';
import slugify from 'slugify';

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();


/**
 * Check if is browser.
 *
 * @return {boolean}
 */
const isBrowser = () => {
	return 'undefined' !== typeof window;
}

/**
 * Sanitize markup or text when used inside dangerouslysetInnerHTML
 *
 * @todo I doubt DOMPurify will work during SSR, test and update accordingly.
 *
 * @param {string} content Plain or html string.
 *
 * @return {string} Sanitized string
 */
const sanitize = content => {
	const decodedContent = entities.decode(content);

	return isBrowser() ? DOMPurify.sanitize(decodedContent) : decodedContent;
};

/**
 * Check if external url.
 *
 * @param {string} suspect Suspected string or url.
 *
 * @return {boolean}
 */
const isExternalUrl = (suspect) => {

	// Return false if not even a valid url.
	if (!validUrl.isWebUri(suspect)) {
		return false;
	}

	return -1 === suspect.indexOf(unTrailingSlashIt(siteURL));
};

/**
 * Extracts slug from internal url.
 *
 * The function does not check for external url for performance
 * since it is used in menu with a lot of urls to parse.
 *
 * @param {string} path Path.
 *
 * @return {string}
 */
const getInternalSlug = path => {
	const pathSplit = path.split('/');
	const split = pathSplit.filter(item => !!item);

	split.splice(0, 2); // Remove http and hostname.

	const slug = split.join('/');

	return slug.includes('#') ? pageSlug(slug) : trailingSlashIt(pageSlug(slug));
};

/**
 * Get date in format of m-d-y
 *
 * @param {string} dateString  Date string, example 2020-05-03T04:41:12
 *
 * @return {string}
 */
const getFormattedDate = (dateString) => {
	if (!dateString) {
		return '';
	}

	const date = new Date(dateString);

	return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
};

/**
 * Get date in format of m.d.y
 *
 * @param {string} dateString Date string, example 06-27-2020
 *
 * @return {string}
 */
const formatNewsDate = (dateString) => {

	if (!dateString) {
		return '';
	}

	return dateString.replace(/-/gi, '.');
};

/**
 * Check if is given breakpoint.
 *
 * @param {string} minSize Minimum device size.
 * @param {string} maxSize Maximum device size.
 *
 * @return {boolean}
 */
const isBreakpoint = (minSize, maxSize) => {
	/**
	 * Size are same as used in src/sass/0-settings/_foundation.scss
	 */
	const sizes = {
		small: 0,
		medium: 768,
		large: 1024,
		xlarge: 1280
	};

	if ('undefined' === typeof window || 'undefined' === sizes[minSize] || 'undefined' === sizes[maxSize]) {
		return false;
	}

	return (window.innerWidth > sizes[minSize] && window.innerWidth < sizes[maxSize]);
};

/**
 * Is small breakpoint.
 *
 * @return {boolean}
 */
const isBreakpointSmall = () => isBreakpoint('small', 'medium');

/**
 * Is medium breakpoint.
 *
 * @return {boolean}
 */
const isBreakpointMedium = () => isBreakpoint('small', 'large');

/**
 * Get breakpoint.
 *
 * @return {string}
 */
const getDevice = () => {
	let device = 'desktop';

	if (isBreakpointSmall()) {
		device = 'mobile';
	} else if (isBreakpointMedium()) {
		device = 'tablet';
	}

	return device;
};

const URIWithHash = (uri) => {
	if (isBrowser()) {
		return trailingSlashIt(uri) + window.location.hash;
	}

	return uri;
};

/**
 * Leading slash ui.
 *
 * @param {string} uri uri.
 *
 * @return {string}
 */
const leadingSlashIt = (uri) => {
	if (!uri) {
		return uri;
	}

	return (0 === uri.indexOf('/')) ? uri : `/${uri}`;
};

const trailingSlashIt = (uri) => {
	if (!uri) {
		return '/';
	}

	return ((uri.length - 1) === uri.lastIndexOf('/')) ? uri : `${uri}/`;
}

const unTrailingSlashIt = (uri) => uri.replace(/\/$/, '');

/**
 * Get comma while iterating an array.
 *
 * @param {array} arr array to iterate.
 * @param {int} index Current index in the interation.
 *
 * @return {string}
 */
const getComma = (arr, index) => {
	return arr.length > 0 && (arr.length - 1) !== index ? ', ' : '';
}

/**
 * Get page slug.
 *
 * @param {string} uri URI.
 *
 * @return {string}
 */
const pageSlug = (uri) => {
	if (!uri) {
		return '/'
	}

	return leadingSlashIt(uri);
}

/**
 * Blog post slug.
 *
 * @param {string} uri URI
 *
 * @return {string}
 */
const postSlug = (uri) => {
	return `/blog${leadingSlashIt(uri)}`;
};

/**
 * Casse study  slug.
 *
 * @param {string} uri URI
 *
 * @return {string}
 */
const caseStudySlug = (uri) => {
	return `${leadingSlashIt(uri)}`;
};

/**
 * Casse study  slug.
 *
 * @param {string} uri URI
 *
 * @return {string}
 */
const webinarSlug = (uri) => {
	return `${leadingSlashIt(uri)}`;
};

const isLocalhost = () => {
	return isBrowser() && 'localhost' === window.location.hostname;
}

/**
 * Function to get opengraph image.
 *
 * @param {Object} seo Seo data.
 *
 * @return {void}
 */
const getOgImage = (seo) => {

	if (isEmpty(seo) || isEmpty(seo.opengraphImage) || isEmpty(seo.opengraphImage.sourceUrl)) {
		return getDefaultOgImage(seo);
	}

	return seo.opengraphImage.sourceUrl;
};

/**
 * Function to get opengraph default image.
 *
 * @param {Object} seo Seo data.
 *
 * @return {void}
 */
const getDefaultOgImage = (seo) => {

	if (
		isEmpty(seo) ||
		isEmpty(seo.social) ||
		isEmpty(seo.social.facebook) ||
		isEmpty(seo.social.facebook.defaultImage) ||
		isEmpty(seo.social.facebook.defaultImage.sourceUrl)
	) {
		return '';
	}

	return seo.social.facebook.defaultImage.sourceUrl;
};

/**
 * Managing setting cookie.
 *
 * @param string name  Name of the cookie.
 * @param mixed  value Value of cookie.
 * @param int    hours Number of hours.
 *
 * @return void
 */
const setCookie = (name, value, hours) => {
	var d = new Date();
	d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
	var expires = 'expires=' + d.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';

};

/**
 * Returns the value of a specified cookie.
 *
 * @param string name Name of the cookie.
 *
 * @returns mixed|string Value of cookie.
 */
const getCookie = (name) => {
	var cookieName = name + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(cookieName) === 0) {
			return c.substring(cookieName.length, c.length);
		}
	}
	return '';
};

/**
 * Function to handle testimonials height.
 *
 * @return void
 */
const handleTestimonialsHeight = () => {
	setTimeout(calculateTestimonialHeight, 500);
};

/**
 * Function to calculate testimonials height.
 *
 * @return void
 */
const calculateTestimonialHeight = () => {
	const testimonialList = document.querySelectorAll('.home-testimonials__list');
	let largestTestimonialHeight = 0;

	for (let index = 0; index < testimonialList.length; index++) {
		testimonialList[index].style.height = `auto`;
	}

	for (let index = 0; index < testimonialList.length; index++) {
		let testimonialHeight = testimonialList[index].offsetHeight;

		if (testimonialHeight > largestTestimonialHeight) {
			largestTestimonialHeight = testimonialHeight;
		}
	}

	for (let index = 0; index < testimonialList.length; index++) {
		testimonialList[index].style.height = `${largestTestimonialHeight}px`;
	}
};

/**
 * Function to trigger custom event on GTM.
 *
 * @return void
 */
const sendContactClickEvent = (tagName, tagParent) => {
	if ('undefined' === typeof window.dataLayer || !tagParent) {
		return;
	}

	window.dataLayer.push({ event: 'formAction', cfEventTag: tagName, cfEventTagParent: tagParent });
};

/**
 * Function to build hierarchical tree from flat array.
 *
 * @param array flat array
 * @return array hirarchical array
 */
const flatListToHierarchical = (
	data = [],
	{ idKey = 'key', parentKey = 'parentId', childrenKey = 'childItems' } = {}
) => {
	// console.log('data', data);
	const tree = [];
	const childrenOf = {};
	data.forEach((item) => {
		const newItem = { ...item };
		const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
		childrenOf[id] = childrenOf[id] || [];
		newItem[childrenKey] = childrenOf[id];
		if (parentId === null || parentId === undefined) {
			tree.push(newItem);
		} else {
			childrenOf[parentId] = childrenOf[parentId] || [];
			childrenOf[parentId].push(newItem);
		}
	});
	return tree;
};

const linkify = (str) => {
	return slugify(str);
}


export {
	getInternalSlug,
	getFormattedDate,
	isBreakpoint,
	isBreakpointSmall,
	isBreakpointMedium,
	getDevice,
	leadingSlashIt,
	trailingSlashIt,
	postSlug,
	pageSlug,
	webinarSlug,
	caseStudySlug,
	isExternalUrl,
	URIWithHash,
	getComma,
	isBrowser,
	isLocalhost,
	sanitize,
	formatNewsDate,
	getOgImage,
	getDefaultOgImage,
	setCookie,
	getCookie,
	handleTestimonialsHeight,
	calculateTestimonialHeight,
	sendContactClickEvent,
	flatListToHierarchical,
	linkify
};