/**
 * External dependencies.
 */
import { useEffect } from 'react';

/**
 * useTheme hook.
 *
 * @return {void}
 */
const useTheme = ( theme ) => {

	const darkClassName = 'dark-mode';

	useEffect( () => {
		if ( 'dark' !== theme  ) {
			document.body.classList.remove( darkClassName )
		}
	}, [] ); // eslint-disable-line

	useEffect( () => {
		if ( 'dark' === theme ) {
			document.body.classList.add( darkClassName );
		}
	}, [ theme ] ); // eslint-disable-line
};

export default useTheme;
