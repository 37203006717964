/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import ArrowBigRight from './../icons/long-arrow-right';

import './style.scss';

/**
 * Generate anchor with big arrow.
 *
 * @param {string} text Anchor Label. Default is 'Read more'
 * @param {string} link Anchor Link to be redirect.
 * @param {string} color Arrow color. Default is '#000'
 * @param {boolean} external Whether link is external or internal
 * @param {string} className Class Name.
 *
 * @return {null|*}
 */
const LinkWithArrow = ( { text = 'Read more', link, color = '#000', external, className = '' } ) => {

	if ( ! link ) {
		return null;
	}

	return external ? (
		<a className={ `link__big-arrow ${ className }` } target="_blank" rel="noopener noreferrer" href={ link }><span className="link__big-arrow-text">{ text }</span> { ArrowBigRight( color ) }</a>
	) : (
		<Link className={ `link__big-arrow ${ className }` } to={ link }><span className="link__big-arrow-text">{ text }</span> { ArrowBigRight( color ) }</Link>
	)
};

LinkWithArrow.propTypes = {
	text: PropTypes.string,
	link: PropTypes.string,
	color: PropTypes.string,
	external: PropTypes.bool,
};

export default LinkWithArrow;
