/**
 * External dependencies.
 */
import React from 'react';

/**
 * ArrowBigRight Component.
 *
 * @param {string} color Color Hex code.
 *
 * @return {jsx}
 */
const ArrowBigRight = ( color ) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="65" height="13" viewBox="0 0 65 13">
		<g fill="none" fillRule="evenodd" stroke={ color } strokeLinecap="square">
			<path d="M7 13L0.5 6.5" transform="matrix(-1 0 0 1 65 0)"/>
			<path d="M7 7L0.5 0.5" transform="matrix(-1 0 0 1 65 0) matrix(1 0 0 -1 0 7)"/>
			<path d="M64.673 6.5L1.327 6.5" transform="matrix(-1 0 0 1 65 0)"/>
		</g>
	</svg>
);

export default ArrowBigRight;
