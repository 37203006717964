/**
 * External dependencies.
 */
import React from 'react';

/**
 * FacebookIcon Component.
 *
 * @return {jsx}
 */
const FacebookIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
		<g fill="none" fillRule="evenodd">
			<circle cx="16.1" cy="16.1" r="16.1"/>
			<path fill="#000" d="M17.604 24.134h-3.337v-7.868H12.6v-2.711h1.667v-1.628c0-2.211.941-3.527 3.616-3.527h2.227v2.712h-1.392c-1.041 0-1.11.379-1.11 1.086l-.005 1.357h2.522l-.295 2.711h-2.227v7.868z"/>
		</g>
	</svg>
);

export default FacebookIcon;
