import React from 'react';
import { isEmpty } from 'lodash';
import ReactPlayer from 'react-player';
import './style.scss';

const ResponsivePlayer = ({ videoUrl }) => {

	if (isEmpty(videoUrl)) {
		return null;
	}

	return (
		<div className='player-wrapper'>
			<ReactPlayer
				className='react-player'
				url={videoUrl}
				width='100%'
				height='100%'
				controls={true}
			/>
		</div>
	)
};

export default ResponsivePlayer;
