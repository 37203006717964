/**
 * External dependencies.
 */
import React from 'react';

/**
 * TwitterIcon Component.
 *
 * @return {jsx}
 */
const TwitterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
		<g fill="none" fillRule="evenodd">
			<circle cx="16.1" cy="16.1" r="16.1"/>
			<path fill="#000" d="M22.192 11.218c.662-.418 1.17-1.08 1.41-1.87-.621.388-1.307.668-2.038.82-.584-.658-1.418-1.068-2.34-1.068-1.772 0-3.207 1.515-3.207 3.382 0 .266.027.524.082.77-2.665-.14-5.028-1.486-6.61-3.534-.277.502-.435 1.082-.435 1.702 0 1.173.566 2.208 1.427 2.816-.526-.017-1.02-.171-1.453-.423v.042c0 1.64 1.104 3.007 2.574 3.316-.27.08-.553.12-.846.12-.207 0-.409-.02-.603-.06.407 1.343 1.592 2.322 2.996 2.348-1.099.908-2.482 1.448-3.984 1.448-.258 0-.514-.014-.765-.046 1.42.959 3.105 1.519 4.917 1.519 5.9 0 9.124-5.154 9.124-9.625 0-.147-.002-.293-.008-.437.627-.477 1.172-1.073 1.6-1.752-.575.27-1.193.451-1.841.532z"/>
		</g>
	</svg>
);

export default TwitterIcon;
