/**
 * External dependencies.
 */
import React from 'react';

/**
 * LinkedinIcon Component.
 *
 * @return {jsx}
 */
const LinkedinIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
		<g fill="none" fillRule="evenodd">
			<circle cx="16.1" cy="16.1" r="16.1"/>
			<path fill="#000" d="M9.479 11.816h2.927v10.201H9.479V11.816zm1.389-1.276h-.021c-1.06 0-1.747-.78-1.747-1.768C9.1 7.764 9.807 7 10.888 7c1.08 0 1.744.763 1.765 1.77 0 .987-.685 1.77-1.785 1.77zm13.25 11.478H20.8v-5.28c0-1.382-.52-2.324-1.661-2.324-.873 0-1.359.636-1.585 1.25-.085.22-.071.526-.071.834v5.52h-3.289s.043-9.352 0-10.202h3.289v1.6c.194-.7 1.245-1.698 2.921-1.698 2.08 0 3.715 1.468 3.715 4.627v5.673z"/>
		</g>
	</svg>
);

export default LinkedinIcon;
